import { Component, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class HomeComponent {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: [
      '<img src="assets/images/arrow_back_black_24dp1.svg">',
      '<img src="assets/images/arrow_back_black_24dp2.svg">'
    ],
    items: 1,
    nav: true,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: true,
    // autoHeight: true,
  }
  services = [
    {
      title : 'يمكن للعملاء التسجيل في برنامج محفوظ مجانًا.',
      description : 'يمكن للعملاء التسجيل في برنامج محفوظ مجانًا. يمكن للعملاء التسجيل في برنامج محفوظ مجانًا. يمكن للعملاء التسجيل في برنامج محفوظ مجانًا. يمكن للعملاء التسجيل في برنامج محفوظ مجانًا.',
    },
    {
      title : 'يمكن للعملاء اختيار الجمعية التي يريدون التبرع لها.',
      description : 'يمكن للعملاء اختيار الجمعية التي يريدون التبرع لها. يمكن للعملاء اختيار الجمعية التي يريدون التبرع لها. يمكن للعملاء اختيار الجمعية التي يريدون التبرع لها. يمكن للعملاء اختيار الجمعية التي يريدون التبرع لها.',
    },
    // التبرع بطرق مختلفة، بما في ذلك الدفع الإلكتروني
    {
      title : 'التبرع بطرق مختلفة، بما في ذلك الدفع الإلكتروني',
      description : 'التبرع بطرق مختلفة، بما في ذلك الدفع الإلكتروني. التبرع بطرق مختلفة، بما في ذلك الدفع الإلكتروني. التبرع بطرق مختلفة، بما في ذلك الدفع الإلكتروني. التبرع بطرق مختلفة، بما في ذلك الدفع الإلكتروني.',
    },
    // تخصيص التبرع للمحفظة الوقفية أو الاستثمارية أو التشغيلية
    {
      title : 'تخصيص التبرع للمحفظة الوقفية أو الاستثمارية أو التشغيلية',
      description : 'تخصيص التبرع للمحفظة الوقفية أو الاستثمارية أو التشغيلية. تخصيص التبرع للمحفظة الوقفية أو الاستثمارية أو التشغيلية. تخصيص التبرع للمحفظة الوقفية أو الاستثمارية أو التشغيلية. تخصيص التبرع للمحفظة الوقفية أو الاستثمارية أو التشغيلية.',
    },
    // سيحصل العميل على مقاعد وقفية لتعليم الطلاب مقابل كل تبرع يقوم به.
    {
      title : 'سيحصل العميل على مقاعد وقفية لتعليم الطلاب مقابل كل تبرع يقوم به.',
      description : 'سيحصل العميل على مقاعد وقفية لتعليم الطلاب مقابل كل تبرع يقوم به. سيحصل العميل على مقاعد وقفية لتعليم الطلاب مقابل كل تبرع يقوم به. سيحصل العميل على مقاعد وقفية لتعليم الطلاب مقابل كل تبرع يقوم به. سيحصل العميل على مقاعد وقفية لتعليم الطلاب مقابل كل تبرع يقوم به.',
    },
    // سيتمكن العميل من متابعة تبرعاته ومحافظه عبر لوحة التحكم الخاصة به.
    {
      title : 'سيتمكن العميل من متابعة تبرعاته ومحافظه عبر لوحة التحكم الخاصة به.',
      description : 'سيتمكن العميل من متابعة تبرعاته ومحافظه عبر لوحة التحكم الخاصة به. سيتمكن العميل من متابعة تبرعاته ومحافظه عبر لوحة التحكم الخاصة به. سيتمكن العميل من متابعة تبرعاته ومحافظه عبر لوحة التحكم الخاصة به. سيتمكن العميل من متابعة تبرعاته ومحافظه عبر لوحة التحكم الخاصة به.',
    },
  ]
}
