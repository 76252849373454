
<nav class="navbar" [ngClass]="{ 'active': toggle }" #navbar>
  <div class="container position-relative"
  >
  <!-- {{toggle}} -->

    <label class="navbar-toggle " id="js-navbar-toggle" for="chkToggle"
    >
    <div class="page"
      (click)="toggle = !toggle"
    >
     <div class="container"
      [ngClass]="{ 'change': toggle }"
     >
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
     </div>
    </div>
    <!-- <div class="hamburger" (click)="toggle = !toggle" [ngClass]="{ 'open': toggle }">
      <span></span>
        <span></span>
        <span></span>
    </div> -->
  </label>
<a href="javascript:void(0);" class="logo">
<img src="assets/logo.svg" alt="logo" />
</a>
<input type="checkbox" id="chkToggle">
<ul class="main-nav" id="js-menu"
[ngClass]="{ 'active': toggle }"
>
<li>
  <a href="javascript:void(0);" class="nav-links "
    [routerLink]="['/home']"
    routerLinkActive="active"
  >الرئيسيه </a>
</li>
<li>
  <a href="javascript:void(0);" class="nav-links"
  [routerLink]="['/about']"
  routerLinkActive="active"
  >حول المركز</a>
</li>
<li>
  <a href="javascript:void(0);" class="nav-links"
    [routerLink]="['/services']"
    routerLinkActive="active"
  >خدماتنا </a>
</li>
</ul>
<div class="navbar-buttons"
>
  <button class="btn btn-primary"
    (click)="ContactUs()"
  >تواصل معنا</button>
</div>
  </div>
</nav>

