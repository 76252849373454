import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  // auto redirect to home page
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path:'',component:HomeComponent },
  { path:'home',component:HomeComponent },
  { path:'services',component:ServicesComponent },
  { path:'about',component:AboutComponent },



];

@NgModule({


  imports: [
    // scroll up on route change
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
    // RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
