<section id="about">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <img src="assets/images/about.png" class="w-100">
      </div>
      <div class="col-lg-6">
        <h2>
          <span >إكرام  </span>
        </h2>
        <p>
          مرحبًا بكم في مشروعنا المبارك
          <b>"محفوظ"</b>
          ،
           الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
        </p>
        <button class="btn btn-primary">
          عرض المزيد
        </button>
      </div>
    </div>
    <br><br><br>
    <div class="row flex-row-reverse
    ">
      <div class="col-lg-6">
        <img src="assets/images/Group 48095531.png" class="w-100">
      </div>
      <div class="col-lg-6">
        <h2>
          <span class="text-primary">محفوظ</span>
        </h2>
        <p>
          مرحبًا بكم في مشروعنا المبارك
          <b>"محفوظ"</b>
          ،
           الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
        </p>
        <button class="btn btn-primary">
          عرض المزيد
        </button>
      </div>
    </div>
  </div>
</section>

