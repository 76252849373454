<section id="slider">
  <div class="container">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide >
        <div class="item">
          <div class="row align-items-center"
          >
            <div class="col-lg-5">
              <img src="assets/images/homeSliderIcon.svg"/>
            </div>
            <div class="col-lg-7">
              <h1>
                تحقيق التنمية المستدامة من خلال دعم المشاريع التعليمية والصحية والاجتماعية
              </h1>
              <p>
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص.
              </p>
              <button class="btn btn-white text-primary">
                عرض المزيد
              </button>
            </div>

          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <div class="row align-items-center"
          >
            <div class="col-lg-5">
              <img src="assets/images/homeSliderIcon.svg"/>
            </div>
            <div class="col-lg-7">
              <h1>
                شركاء النجاح في تحقيق التنمية المستدامة من خلال دعم المشاريع التعليمية والصحية والاجتماعية والبيئية
              </h1>
              <p>
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص.
              </p>
              <button class="btn btn-white text-primary">
                عرض المزيد
              </button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <div class="row align-items-center"
          >
            <div class="col-lg-5">
              <img src="assets/images/homeSliderIcon.svg"/>
            </div>
            <div class="col-lg-7">
              <h1>
                <!-- شركاء النجاح في تحقيق التنمية المستدامة من خلال دعم المشاريع التعليمية والصحية والاجتماعية والبيئية -->
                  ضع بصمتك في تحقيق التنمية المستدامة من خلال دعم المشاريع التعليمية والصحية والاجتماعية والبيئية والتنموية والتربوية
              </h1>
              <p>
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص.
              </p>
              <button class="btn btn-white text-primary">
                عرض المزيد
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>
<section id="about">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <img src="assets/images/about.png" class="w-100">
      </div>
      <div class="col-lg-6">
        <h2>
          من هو موقع
          <br>
          <span class="text-primary">محفوظ <span>! </span> </span>
        </h2>
        <p>
          مرحبًا بكم في مشروعنا المبارك
          <b>"محفوظ"</b>
          ،
           الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
        </p>
        <button class="btn btn-primary">
          عرض المزيد
        </button>
      </div>
    </div>
  </div>
</section>
<section id="tqn" class="cards">
  <div class="container">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2">
            <img src="assets/images/tqn.svg" class="w-100">
          </div>
          <div class="col-lg-10">
            <h2>
              محفوظ هو جزء من تقن
            </h2>
            <p>
              مرحبًا بكم في مشروعنا المبارك "محفوظ"، الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
            </p>
            <button class="btn btn-primary">
              عرض المزيد
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="services">
  <div class="container">
    <div class="title">
      <h1>
        شرح مفصل لكيفية عمل الخدمة
      </h1>
      <p>
        مرحبًا بكم في مشروعنا المبارك "محفوظ"، الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
      </p>
    </div>
    <section class="cards">
      <div class="row">
        <div class="col-lg-4" *ngFor="let service of services">
          <div class="card">
            <div class="card-body">
              <h2>
                {{service.title}}
              </h2>
              <p>
                {{service.description}}
              </p>
              <button class="btn btn-primary"
              >
                عرض المزيد
              </button>
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
</section>
<section id="goals" class="cards">
  <div class="container">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2">
            <img src="assets/images/Group 48095481.svg" class="w-100">
          </div>
          <div class="col-lg-10">
            <h2>
              اهدافنا
            </h2>
            <p>
              مرحبًا بكم في مشروعنا المبارك "محفوظ"، الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
            </p>
            <button class="btn btn-primary">
              عرض المزيد
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2">
            <img src="assets/images/Group 48095480.svg" class="w-100">
          </div>
          <div class="col-lg-10">
            <h2>
              رسالتنا
            </h2>
            <p>
              مرحبًا بكم في مشروعنا المبارك "محفوظ"، الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
            </p>
            <button class="btn btn-primary">
              عرض المزيد
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2">
            <img src="assets/images/Group 48095480.svg" class="w-100">
          </div>
          <div class="col-lg-10">
            <h2>
              رسالتنا
            </h2>
            <p>
              مرحبًا بكم في مشروعنا المبارك "محفوظ"، الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
            </p>
            <button class="btn btn-primary">
              عرض المزيد
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2">
            <img src="assets/images/Group 48095482.svg" class="w-100">
          </div>
          <div class="col-lg-10">
            <h2>
              رؤيتنا
            </h2>
            <p>
              مرحبًا بكم في مشروعنا المبارك "محفوظ"، الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
            </p>
            <button class="btn btn-primary">
              عرض المزيد
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="newsletter" class="cards">
  <div class="container">
    <div class="card">
      <div class="card-body">
        <!-- email verification.svg -->
        <div class="row align-items-center
         ">
          <div class="col-lg-3">
            <img src="assets/images/email verification.svg" style="width: 100% !important;">
          </div>
          <div class="col-lg-9">
            <div class="row">
              <div class="col-lg-6">
                <h2>
                  اشترك بالنشرة الاخبارية
                </h2>
                <p>
                  اشترك بالنشرة الاخبارية ليصلك كل جديد
                </p>
              </div>
              <div class="col-lg-6">
                <form >
                  <input type="text" class="form-control" placeholder="البريد الالكتروني">
                  <button class="btn btn-primary">
                    اشترك
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
