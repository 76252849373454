<footer>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <img src="assets/logo.svg" class="logo" />
        <h5 class="app-name">
          محفوظ
        </h5>
        <p>
          مرحبًا بكم في مشروعنا المبارك "محفوظ"، الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات
        </p>
      </div>
      <div class="col-lg-3">
        <ul>
          <li><a href="javascript:void(0);">حول الخدمة</a></li>
          <li><a href="javascript:void(0);">الاحكام والشروط</a></li>
          <li><a href="javascript:void(0);">السياسيات والخصوصية</a></li>
        </ul>
      </div>
      <div class="col-lg-4">
        <ul>
          <li><a href="tel:01000000000"
            ><img src="assets/images/phone.svg" /><span>01000000000</span></a></li>
          <li><a href="mailto:mahfoud@email.com"
            ><img src="assets/images/email.svg" /><span>mahfoud@email.com</span></a></li>
        </ul>
        <!--
          social media icons
         -->
        <ul class="social-media">
          <li><a href="https://twitter.com/" target="_blank">
            <img src="assets/images/t.svg" />
          </a></li>
          <li><a href="https://www.facebook.com/" target="_blank">
            <img src="assets/images/f.svg" />
          </a></li>
          <li><a href="https://www.instagram.com/explore/" target="_blank">
            <img src="assets/images/i.svg" />
          </a></li>
          </ul>
      </div>
    </div>
    <hr>
    <div>
      <p class="m-0 text-center">
        تم الصنع بكل حب واهتمام
        <span
          class="heart"
        >❤️</span>
        بواسطة Badr Alhariri Team
      </p>
    </div>
  </div>

</footer>
