<section id="about">
  <div class="container">
    <div class="row">
      <div class="col-lg-3">
        <img src="assets/images/Group 48095540.png" class="w-100">
      </div>
      <div class="col-lg-9">
        <h2>
          محفوظ
        </h2>
        <p>
          مرحبًا بكم في مشروعنا المبارك
          <b>"محفوظ"</b>
          ،
           الذي يهدف إلى دعم الجمعيات الإسلامية من خلال توفير وسيلة سهلة وآمنة للتبرعات الإلكترونية. يعتبر "محفوظ" منصة تفاعلية تتيح للمتبرعين السهولة في الدعم المالي للأعمال الخيرية والجمعيات التعليمية والاجتماعية داخل المجتمعات الإسلامية. هدفنا الرئيسي هو تسهيل عملية التبرع وجعلها ملائمة وشفافة للمتبرعين.
        </p>
        <div class="btns">
          <button class="btn btn-primary">
            <img src="assets/images/phone.svg" />
            +123 456 789
          </button>
          <button class="btn btn-blue text-white">
            <img src="assets/images/email.svg" />
            mahfoud@email.com
          </button>
          <button class="btn btn-white">
            <img src="assets/images/document-text 1.svg" />
            certificate: ARFH144
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  id="about"
>
  <div class="container">
    <h1>
      عنوان كبير
    </h1>
    <p>
      لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.
    </p>
    <h1>
      عنوان كبير
    </h1>
    <p>
      لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.
    </p>
    <h1>
      عنوان كبير
    </h1>
    <p>
      لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور برامج النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.
    </p>
  </div>
</section>
