<form action="">
  <!-- Group 48095522.svg -->
  <div class="text-center">
    <img src="assets/images/Group 48095522.svg" class="w-100" />
    <h2>
      تواصل معنا
    </h2>
    <p>
      لا تتردد في التواصل معنا في أي وقت
    </p>
  </div>

  <div class="mt-3">
    <!-- الاسم الكامل -->
    <div class="form-group">
      <!-- <label for="name">الاسم الكامل</label> -->
      <input type="text" class="form-control" id="name" placeholder="الاسم الكامل">
    </div>
    <!-- البريد الالكتروني -->
    <div class="form-group">
      <!-- <label for="email">البريد الالكتروني</label> -->
      <input type="email" class="form-control" id="email" placeholder="البريد الالكتروني">
    </div>
    <!-- كيف نقدر نساعدك -->
    <div class="form-group">
      <!-- <label for="message">كيف نقدر نساعدك</label> -->
      <textarea class="form-control" id="message" rows="3"
        placeholder="كيف نقدر نساعدك"
      ></textarea>
    </div>
    <!-- submit button -->
    <button type="submit" class="btn btn-primary w-100">تواصل معنا</button>
  </div>
</form>
