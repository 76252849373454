import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsComponent } from 'src/app/contact-us/contact-us.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  toggle = false;
  @ViewChild('navbar') navbar: any;
  constructor(public dialog: MatDialog) {
    // this.ContactUs();
  }

  ContactUs(): void {
    const dialogRef = this.dialog.open(ContactUsComponent, {
      data: {},
      width: '450px',
      maxHeight: '90vh',

    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  // ContactUsComponent
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e:any) {
    var element :any = this.navbar.nativeElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('sticky');
    } else {
      element.classList.remove('sticky');
    }
  }
}
